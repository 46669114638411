import Keycloak from 'keycloak-js'


const getTenantFromUrl = () => {
   const path = window.location.pathname;
   // Extract the first part after the domain
   const tenant = path.split('/')[1]; 
   return tenant;
};

const tenant = getTenantFromUrl();
 

const clientId = tenant ==="payjack"? tenant: window._env_.REACT_APP_CLIENT;
window._env_.REACT_APP_CLIENT = clientId;

const keycloakConfig = {   
   url: window._env_.REACT_APP_URL,
   realm: window._env_.REACT_APP_REALM,
   clientId: clientId
}
const keycloak = new Keycloak(keycloakConfig);
keycloak.init({
   onLoad: 'login-required',
   silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
});
export default keycloak
